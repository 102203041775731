import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import "firebase/compat/functions";

var config = {
  apiKey: "AIzaSyDwqeEX-PH5IV9pMboDUSgz0g6nWAoTTho",
  authDomain: "el-ocho.firebaseapp.com",
  databaseURL: "https://el-ocho.firebaseio.com",
  projectId: "el-ocho",
  storageBucket: "el-ocho.appspot.com",
  messagingSenderId: "770366666625",
  appId: "1:770366666625:web:f6d79634f54385a09b1a80",
  measurementId: "G-2PBQGFMTGH"
};

firebase.initializeApp(config);

// firebase.functions().useFunctionsEmulator("http://localhost:5001");

firebase.auth().languageCode = "es";

export const db = firebase.firestore();
export const fb = firebase;
