import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "login",
    meta: {
      appFrame: false,
      navBar: false,
      basePath: true,
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/dashboard/login.vue"),
  },

  //CORPORATE COUPONS PAGE
  {
    path: "/general",
    name: "corporative-coupons-general",
    meta: {
      transitionName: "fade",
      appFrame: true,
      titlepage: "Información General",
      navBar: true,
      allowedRoles: ["corporateCoupons","sudo"],
    },
    component: () => import("../views/dashboard/menu/general.vue"),
  },
  {
    path: "/amounts",
    name: "corporative-coupons-amounts",
    meta: {
      transitionName: "fade",
      appFrame: true,
      titlepage: "Montos permitidos",
      navBar: true,
      allowedRoles: ["corporateCoupons","sudo"],
    },
    component: () =>
      import("../views/dashboard/menu/amounts/amounts.vue"),
  },

  {
    path: "/users",
    name: "corporative-coupons-users",
    meta: {
      transitionName: "fade",
      appFrame: true,
      navBar: true,
      titlepage: "Beneficiados",
      allowedRoles: ["corporateCoupons","sudo"],
    },
    component: () =>
      import("../views/dashboard/menu/user-lists/users.vue"),
  },

  {
    path: "/list",
    name: "corporative-coupons-coupons",
    meta: {
      transitionName: "fade",
      appFrame: true,
      navBar: true,
      titlepage: "Cupones ",
      allowedRoles: ["corporateCoupons","sudo"],
    },
    component: () =>
      import("../views/dashboard/menu/coupons/coupons.vue"),
  },
  {
    path: '/404', name: 'NotFound', component: () =>
      import(/* webpackChunkName: "about" */ "../views/404.vue"),
  },
  {
    path: '/:catchAll(.*)', redirect: '404'
  },





  // {
  //   path: '/paid',
  //   name: 'paid',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/paid.vue')
  // }
];

const router = new VueRouter({
  base: "/",
  mode: "history",
  routes,
});

export default router;
