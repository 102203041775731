<template>
  <v-app class="app-vue" id="inspire">
    <v-app-bar
      color="primary-color"
      dark
      v-if="appFrame && isPhone"
      height="56px"
      style="height: 56px !important"
    >
      <!-- <v-toolbar-title>My files</v-toolbar-title> -->

      <v-text-field
        v-if="$store.state.visibleSearch"
        v-model="search"
        solo-inverted
        height="20px"
        dark
        filled
        style="border-radius: 15px !important"
        flat
        dense
        hide-details
        :label="`Buscar ${$store.state.searchTerm}`"
        prepend-inner-icon="fa-search"
        class="d-md-flex color-search"
      />
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
    </v-app-bar>
    <!-- <v-btn @click.stop="drawer = !drawer"> open </v-btn> -->
    <v-app-bar
      app
      color="#fff"
      height="100px"
      key="app_bar"
      clipped-right
      elevation="0"
      class="appbar"
      v-if="appFrame && !isPhone"
    >
      <div class="user ml-6">
        <div class="circulo-a"></div>
        <div class="circulo-b"></div>
        <v-list-item
          two-line
          class="pa-0 my-1"
          style="border-radius: 15px !important"
        >
          <v-list-item-avatar class="my-1 ml-2" style="border: solid #fff 2px">
            <img src="~@/assets/avatar-default.png" />
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="font-weight-bold text-blanco">
              {{ user_.name }}
            </v-list-item-title>

            <v-list-item-subtitle class="text-blanco">
              {{ user_.type == "sudo" ? "Administrador" : "Socio Comercial" }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </div>
      <v-text-field
        v-if="$store.state.visibleSearch"
        v-model="search"
        solo-inverted
        height="30px"
        light
        filled
        style="border-radius: 15px !important"
        flat
        hide-details
        :label="`Buscar ${$store.state.searchTerm}`"
        prepend-inner-icon="fa-search"
        class="d-none d-md-flex color-search"
      >
        <!-- <template v-slot:append-outer>
              <img  src="@/assets/pulpito.svg" width="30px" alt="" srcset="" />
              <v-icon large color="#fff">fas fa-question-circle</v-icon>
        </template> -->
      </v-text-field>

      <v-spacer />

      <span
        class="headline font-weight-bold ml-0 mr-5 d-none d-md-block"
        style="position: relative; color: #ff5900; top: -6px"
      >
        Plataforma
        <span
          style="
            position: absolute;
            bottom: -22px;
            left: 0;
            font-size: 14px;
            font-weight: normal;
            color: rgba(0, 0, 0, 0.4);
            width: 100%;
            text-align: right;
          "
        >
          Administrativa
        </span>
      </span>
    </v-app-bar>
    <Snackbar style="z-index: 1000" />

    <v-navigation-drawer
      v-model="drawer"
      app
      color="primary-color"
      :mini-variant.sync="mini"
      permanent
      class="navigations primary-color b-r"
      key="app_nav"
      dark
      v-if="navBar && !isPhone"
    >
      <div class="logo-l">
        <Logo :type="mini" :style="`width: ${mini ? '35px' : '150px '}`" />

        <v-btn v-if="!mini" absolute right icon @click.stop="mini = !mini">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
      </div>

      <v-list nav dense style="border-radius: 15px !important">
        <v-divider class="mb-3"></v-divider>

        <v-list-item-group
          v-model="selectedItem"
          dark
          style="border-radius: 15px !important"
        >
          <v-list-item
            v-for="(item, i) in typeMenu_list"
            :key="i"
            dark
            class="border-r pl-3"
            style="border-radius: 15px !important"
            :to="`${item.link}`"
          >
            <v-list-item-icon>
              <v-icon v-text="item.icon"></v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title v-text="item.text"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>

      <div class="actions-closed">
        <v-btn
          @click="logOut"
          color="ligth"
          style="padding: 10px !important; margin: 5px; border-radius: 15px"
          block
          large
          :elevation="0"
        >
          <v-icon small>fa-sign-out-alt</v-icon>
          <span v-if="!mini" class="ml-2 white--text font-weight-500">
            Cerrar Sesión
          </span>
        </v-btn>
      </div>
    </v-navigation-drawer>
    <div class="disclamer" v-if="!isPhone">
      <span>
        <i class="fas fa-code-branch"></i>
        v 0.1.0 de
        <i class="fas fa-mug-hot"></i> a <i class="fas fa-code"></i> por:
        <b>
          <a class="white--text" href="http://ocho.life" target="_blank"
            >OCHO APP.
          </a>
        </b>
        &copy; Ocho Corporation S.A. de C.V.
      </span>
    </div>
    <v-navigation-drawer
      app
      v-model="drawer"
      absolute
      temporary
      color="primary-color"
      dark
      v-if="navBar && isPhone"
    >
      <div class="logo-l">
        <Logo :type="mini" :style="`width: ${mini ? '30px' : '100px '}`" />
      </div>

      <v-list nav dense style="border-radius: 15px !important">
        <v-divider class="mb-3"></v-divider>

        <v-list-item-group
          v-model="selectedItem"
          dark
          style="border-radius: 15px !important"
        >
          <v-list-item
            v-for="(item, i) in typeMenu_list"
            :key="i"
            dark
            class="border-r pl-3"
            style="border-radius: 15px !important"
            :to="`${item.link}`"
          >
            <v-list-item-icon>
              <v-icon v-text="item.icon"></v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title v-text="item.text"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>

      <div class="actions-closed">
        <v-btn
          @click="logOut"
          color="ligth"
          style="padding: 10px !important; margin: 5px; border-radius: 15px"
          block
          large
          :elevation="0"
        >
          <v-icon small>fa-sign-out-alt</v-icon>
          <span v-if="!mini" class="ml-2 white--text font-weight-500">
            Cerrar Sesión
          </span>
        </v-btn>
      </div>
    </v-navigation-drawer>

    <v-main class="main">
      <!-- <TransitionPage> -->
      <router-view></router-view>
      <!-- </TransitionPage> -->
    </v-main>
  </v-app>
</template>

<script>
import { db, fb } from "@/firebase";
import { mapActions, mapState } from "vuex";
import TransitionPage from "./components/TransitionPage";
import Snackbar from "@/components/snackbar";
import Logo from "@/components/logo";

export default {
  name: "App",
  components: {
    Snackbar,
    Logo,
    TransitionPage,
  },
  data: () => ({
    admins: [
      ["Management", "mdi-account-multiple-outline"],
      ["Settings", "mdi-cog-outline"],
    ],

    baseRoute: {
      corporateCoupons: "/list",
      sudo: "/list",
    },
    mini: false,
    version: process.env.VUE_APP_VERSION,
    loading: true,
    drawer: true,
    appFrame: null,
    navBar: null,
    userData: {},
    loading: false,
    selectedItem: 0,

    defaulImage: require("@/assets/templateImage.svg"),
    selectedBusiness_lMenu: [
      {
        text: "General",
        icon: "fa fa-cogs",
        link: "/general",
      },
      {
        text: "Montos permitidos",
        icon: "fa fa-calculator",
        link: "/amounts",
      },
      {
        text: "Beneficiados",
        icon: "fa fa-users",
        link: "/users",
      },
      {
        text: "Cupones",
        icon: "fa fa-ticket",
        link: "/list",
      },
    ],
  }),

  methods: {
    ...mapActions([
      "titlepage",
      "setUser",
      "Alert_",
      "setUserValidity_",
      "addBusiness",
    ]),
    logOut() {
      if (this.user) {
        fb.auth()
          .signOut()

          .then(() => {
            this.$router.push({
              path: "/",
            });

            this.addBusiness(null);
          });
      }
    },
    getBussines(id) {
      db.collection("corporateCoupons")
        .doc(id)
        .onSnapshot((response) => {
          let business = response.data();
          business[".key"] = response.id;
          // this.selectedBusiness_l = business;
          this.addBusiness(business);
          // console.debug(business);
        });
    },
  },
  mounted() {
    setTimeout(() => {
      this.loading = false;
    }, 3000);

    this.appFrame = this.$route.meta.appFrame;
    this.navBar = this.$route.meta.navBar;
    this.titlepage(this.$route.meta.titlepage);

    fb.auth().onAuthStateChanged((authUser) => {
      if (this.$route.meta) {
        if (authUser) {
          this.$binding("userData", db.collection(`appUsers`).doc(authUser.uid))
            .then((user) => {
              if (!user.type || this.$store.state.user.active == false) {
                // console.debug("no entra");
                this.setUserValidity_(false);
                this.Alert_({
                  text: "Este perfíl de usuario no tiene permitido ingresar a la plataforma",
                  timeout: 2000,
                  btn_closed: true,
                  icon: false,
                  iconType: "mdi-check",
                  type: "error",
                });
                this.logOut();
              } else {
                this.setUser(user);
                if (user.type == "corporateCoupons" || "sudo") {
                  this.getBussines(user.businessID);
                }

                if (this.$router.currentRoute.name == "login") {
                  setTimeout(() => {
                    this.$router.push({
                      path: this.baseRoute[this.user_.type],
                    });
                  }, 2000);
                } else if (
                  this.$router.currentRoute.meta.allowedRoles &&
                  !this.$router.currentRoute.meta.allowedRoles.includes(
                    user.type
                  )
                ) {
                  //validating user permissions for this route
                  this.$router.push({ path: this.baseRoute[this.user_.type] });
                }
                if (this.selectedBusiness) {
                  // document.getElementById("businessMenu").click();
                } else {
                  this.addBusiness(null);
                }
              }
            })
            .catch((error) => {
              this.Alert_({
                text: "Este perfíl de usuario no tiene permitido ingresar a la plataforma",
                timeout: 2000,
                btn_closed: true,
                icon: false,
                iconType: "mdi-check",
                type: "error",
              });
              this.loading = false;
              this.logOut();
            });
        } else {
          if (this.$router.history.current.fullPath != "/")
            this.$router.push({ path: "/" });
        }
      }
    });
  },
  computed: {
    ...mapState([
      "userType",
      "role",
      "user",
      "selectedBusiness",
      "search",
      "selectedCorporative",
    ]),
    search: {
      get: function () {
        return this.$store.state.search;
      },
      set: function (newValue) {
        this.$store.commit("setSearch", newValue);
      },
    },
    isPhone() {
      const is = screen.width < 500 ? true : false;
      return is;
    },
    user_() {
      return this.user;
    },
    typeMenu_list() {
      if (this.role == "corporateCoupons" || this.role == "sudo") {
        return this.selectedBusiness_lMenu;
      } else {
        return [];
      }
    },
  },
  watch: {
    $route(e) {
      this.appFrame = e.meta.appFrame;
      this.navBar = e.meta.navBar;
      this.titlepage(e.meta.titlepage);
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/scss/brands.scss";
@import "~@/assets/scss/fontawesome.scss";
@import "~@/assets/scss/light.scss";
@import "~@/assets/scss/regular.scss";
@import "~@/assets/scss/solid.scss";
@import "@/_responsive.scss";
@import "@/main.scss";
.app-vue {
  display: flex;
  justify-content: center;
  align-items: center;
}
.main {
  overflow-x: hidden;
  @include responsive(mobile) {
    padding-top: 0px;
  }
}
@font-face {
  font-family: "nexa";
  src: url("~@/assets/fonts/nexa_regular-webfont.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "nexa";
  src: url("~@/assets/fonts/nexa_light-webfont.woff2") format("woff2");
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: "nexa";
  src: url("~@/assets/fonts/nexa_bold-webfont.woff2") format("woff2");
  font-weight: bold;
  font-style: normal;
}
.icon-logo {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.v-snack__content {
  text-align: center !important;
}
.border-r {
  border-radius: 15px !important;
  overflow: hidden !important;
}
.actions-closed {
  height: 60px;
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}
.v-application--is-ltr .v-list-item__action:first-child,
.v-application--is-ltr .v-list-item__icon:first-child {
  margin-right: 6px !important;
}

.v-icon.v-icon {
  font-size: 16px !important;
}
.navigations {
  height: calc(100vh - 55px) !important;
  margin: 15px;
  -webkit-filter: drop-shadow(0 0 10px rgba(255, 89, 0, 0.429));
  filter: drop-shadow(0 0 10px rgba(255, 89, 0, 0.415));
}
.logo-l {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
  transform: translateX(-15px);
}
.user {
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: radial-gradient(
    circle at 10% 10%,
    #ff5900,
    #ff8f53,
    #ff5900
  ) !important;
  border-radius: 10px;
  box-sizing: border-box;
  padding-left: 10px;
  min-width: 200px;
  height: 70px;
  margin-right: 100px;
  color: #fff !important;
  position: relative;
  clip-path: fill-box;

  overflow: hidden;
  .circulo-a {
    width: 15px;
    height: 15px;
    border-radius: 10px;
    background-color: #fff;
    position: absolute;

    left: -7.5px;
  }
  .circulo-b {
    width: 15px;
    height: 15px;
    border-radius: 10px;
    background-color: #fff;
    position: absolute;

    right: -7.5px;
  }
  @include responsive(mobile) {
    height: auto;
    min-width: auto;
    padding-right: 20px;
    //... all what you want
  }
}
/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(10px);
  opacity: 0;
}

.control-input {
  width: 100%;
}
.text-blanco {
  color: #fff !important;
}
input[type="color"] {
  height: 50px !important;
  border-radius: 15px !important;
}
.appbar {
  top: 0;

  position: fixed !important;

  padding-bottom: 20px !important;
  z-index: 1000;
  @include responsive(mobile) {
    position: relative !important;
    height: auto;
    background-color: aqua;
    display: flex;
    flex-direction: column;
    //... all what you want
  }
}
.disclamer {
  position: fixed;
  bottom: 0px;
  right: 0px;
  width: 100%;
  z-index: 10;
  background-color: #272727;
  color: #fff !important;
  padding: 3px 10px;
  font-size: 12px;
  text-align: center;
  border-radius: 10px 10px 0px 0px;
}
</style>
