import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

Vuetify.config.silent = true

export default new Vuetify({
    theme: {
        dark: false,
        options: {
            customProperties: true,
        },
        themes: {
            light: {
                primary: '#ff5900',
                secondary: '#5170b5',
                accent: '#f06a25',
                error: '#FF5252',
                info: '#2196F3',
                success: '#4CAF50',
                warning: '#FFC107'
            },
            dark: {
                primary: '#f06a25',
                secondary: '#5170b5',
                accent: '#f06a25',
                error: '#FF5252',
                info: '#2196F3',
                success: '#4CAF50',
                warning: '#FFC107'
            },
        },
    },
    lang: {
        current: 'es',
    },
    icons: {
        iconfont: 'fa',
    },
});
